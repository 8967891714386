<template>
    <div>
        <b-table
            striped
            bordered
            small
            hover
            sticky-header="true"
            head-variant="light"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
            responsive="sm"
            :items="devUserList"
            :fields="fields"
        >
        </b-table>
        <div class="text-center">
            <b-container>
                <b-row>
                    <b-col>
                        <b-button variant="success" @click="addUser">Add Associate</b-button>
                    </b-col>
                    <b-col>
                        <b-button variant="danger" @click="delUser">Remove Associate</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
export default {
    name: "DeviceUsers",
    data() {
        return {
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'pin', label: 'Pin'}
            ],
            selected: null
        }
    },
    mounted() {

    },
    created() {
        this.$store.commit('storeDeviceUsers', []);
        this.$store.dispatch('getDeviceUsers', {
            id: this.$store.getters.adminSelectedDev.deviceId,
        }).then(() => {

        }).catch(() => {
            console.log("Error Getting Device Users");
        });
    },
    methods: {
        onRowSelected(items) {
            this.selected = items[0];
        },
        addUser() {
            if (this.$store.getters.adminSelectedUser.reg !== 4) {
                this.$bvToast.toast('User needs to be fully registered first', {
                    title: 'Add Associate To Device',
                    variant: 'danger',
                    solid: true
                });
                return;
            }
            this.$store.dispatch('addDeviceGuestUser', {
                username: this.$store.getters.adminSelectedUser.username,
                id: this.$store.getters.adminSelectedDev.deviceId
            }).then(() => {
                this.$bvToast.toast('Associate Has Been Add To Device', {
                    title: 'Add Associate To Device',
                    variant: 'success',
                    solid: true
                });
                this.$store.dispatch('getDeviceUsers', {
                    id: this.$store.getters.adminSelectedDev.deviceId,
                });
            }).catch(() => {
                this.$bvToast.toast('Error Adding Associate To Device', {
                    title: 'Add Associate To Device',
                    variant: 'danger',
                    solid: true
                });
            })
        },
        delUser() {
            let devUserList = this.$store.getters.deviceUsers;

            if (this.selected != null) {
                for (let i = 0; i < devUserList.length; i++) {
                    if (this.selected.name === devUserList[i].name && this.selected.pin === devUserList[i].pin) {
                        if (devUserList[i].main) {
                            this.$bvToast.toast("Can't Remove Primary Device User", {
                                title: 'Remove Associate',
                                variant: 'danger',
                                solid: true
                            });
                            return;
                        }
                        this.$store.dispatch('remDeviceGuestUser', {
                            username: this.selected.name,
                            id: this.$store.getters.adminSelectedDev.deviceId
                        }).then(() => {
                            this.$bvToast.toast('Associate Has Been Remove From Device', {
                                title: 'Remove Associate From Device',
                                variant: 'success',
                                solid: true
                            });
                            this.$store.dispatch('getDeviceUsers', {
                                id: this.$store.getters.adminSelectedDev.deviceId,
                            });
                        }).catch(() => {
                            this.$bvToast.toast('Error Removing Associate From Device', {
                                title: 'Remove Associate From Device',
                                variant: 'danger',
                                solid: true
                            });
                        });
                        return;
                    }
                }
            }
        }
    },
    computed: {
        devUserList() {
            return this.$store.getters.deviceUsers;
        }
    }
}
</script>

<style scoped>

</style>
