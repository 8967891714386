import { render, staticRenderFns } from "./AssociateList.vue?vue&type=template&id=cf0992e8&scoped=true&"
import script from "./AssociateList.vue?vue&type=script&lang=js&"
export * from "./AssociateList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf0992e8",
  null
  
)

export default component.exports