<template>
  <div>
      <b-container>
          <b-row
              cols="5"
              align-content="between"
          >
              <b-col cols="12" md="9">
                  <h3>Edit Device Users</h3>
              </b-col>
              <b-col cols="12" md="3">
                  <admin-device-menu></admin-device-menu>
              </b-col>
          </b-row>
          <b-row>
              <b-col>
                  <p>{{devCustomer}} - {{devId}}</p>
              </b-col>
          </b-row>
      </b-container>
    <hr>
    <div class="text-center">
      <b-card title="Associate Users" class="mb-1 ml-2 mr-1">
        <associate-list></associate-list>
      </b-card>
    </div>
    <div>
        <hr>
        <h4 class="text-center">Device Users</h4>
        <hr>
        <device-users></device-users>
    </div>
  </div>
</template>

<script>
import AssociateList from "@/components/AdminModules/Users/AssociateList";
import DeviceUsers from "@/components/AdminModules/Devices/DeviceUsers";
import AdminDeviceMenu from "@/components/AdminDeviceMenu";

export default {
  name: "AdminEditDevUsers",
  props: ['id'],
  data() {
    return {
      devId: null,
      userId: null,
    }
  },
  components: {
      AssociateList,
      DeviceUsers,
      AdminDeviceMenu
  },
  created() {
    this.devId = this.$route.params.id;
    this.userId = this.devIdToUserId(this.devId);

  },
  mounted() {

  },
  methods: {
    devIdToUserId(value) {
      if (this.$store.getters.adminDevList.length !== 0) {
        const devList = this.$store.getters.adminDevList;
        for (let i = 0; i < devList.length; i++) {
          if (devList[i].deviceId === value)
            return devList[i].userId;
        }
      }
      return 'None';
    }
  },
    computed: {
        devCustomer () {
            if (this.$store.getters.deviceSys === null)
                return "";
            else
                return this.$store.getters.deviceSys.sysName;
        }
    }
}
</script>

<style scoped>

</style>
