<template>
    <div>
        <b-container>
            <b-row cols="3" align-v="center" align-h="between">
                <b-col>
                    Per Page: <b-select
                    style="width: 6rem"
                    :options="perPageOption"
                    v-model="perPage"
                    @change="perPageChange"
                ></b-select>
                </b-col>
                <b-col>
                    <b-button variant="primary" @click="refreshUserList">Refresh List</b-button>
                </b-col>
                <b-col>
                    <b-form-group
                        label="Filter"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-container>
        <p></p>
        <div class="text-center">
            <b-table
                striped
                bordered
                small
                hover
                selectable
                select-mode="single"
                head-variant="light"
                responsive="sm"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-selected="onRowSelected"
                @row-contextmenu="rightClicked"
                style="overflow-y: hidden"
            >
            </b-table>
            <div>
                <b-container class="border border-info">
                    <b-row cols="4" align-v="center" align-h="center">
                        <b-col cols="3">
                            <b-card-text class="mt-1 mb-1">Currently Selected User:</b-card-text>
                        </b-col>
                        <b-col>
                            <b-form-input class="text-center mt-1 mb-1" v-model="selUser" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-container>
                <p></p>
            </div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
            ></b-pagination>
            <hr>
        </div>
    </div>
</template>

<script>
export default {
    name: "AssociateList",
    data() {
        return {
            fields: [
                {key: 'username', label: 'Username', sortable: true},
                {key: 'firstname', label: 'First Name', sortable: true},
                {key: 'lastname', label: 'Last Name', sortable: true},
                {key: 'email', label: 'Email', sortable: true},
                {key: 'company', label: 'Company', formatter: this.compFieldFormat, sortable: true},
                {key: 'role', label: 'Role', formatter: this.roleFieldFormat, sortable: true},
                {key: 'reg', label: 'Registration', formatter: this.regFieldFormat, sortable: true},
                {key: 'status', label: 'Status', formatter: this.statusFieldFormat, sortable: true},
                {key: 'lastLogin', label: 'Last Login', formatter: this.dateFieldFormat, sortable: true}
            ],
            perPageOption: [5, 10, 25, 50, 100],
            sortBy: 'username',
            sortDesc: false,
            selected: null,
            selectedUser: null,
            roleSelected: 0,
            roleOptions: [
                {value: 0, text: "Guest User"},
                {value: 1, text: "User"},
                {value: 2, text: "Tech"},
                {value: 3, text: "Manager"},
                {value: 4, text: "Admin"},
                {value: 5, text: "Super"}
            ],
            regSelected: 0,
            regOptions: [
                {value: 0, text: "Not Registered"},
                {value: 1, text: "Needs to Verify Email"},
                {value: 2, text: "Needs to Change Password"},
                {value: 3, text: "Not Used"},
                {value: 4, text: "Registered"},
                {value: 5, text: "Reset Password Request"}
            ],
            statSelected: 0,
            statOptions: [
                {value: 0, text: "Active"},
                {value: 1, text: "Disabled"},
                {value: 2, text: "Suspended"}
            ],
            perPage: 5,
            currentPage: 1,
            totalRows: 1,
            rowContext: null,
            contextUser: '',
            checkOpt: true,
            filter: null,
            filterOn: ['username', 'firstname', 'lastname']
        }
    },
    created() {
        this.$store.dispatch('getCompList');
        this.totalRows = this.$store.getters.associateList.length;
        this.perPage = this.$store.getters.getAdminAsscPerPage;
        this.$store.dispatch('getAdminUserList').then(() => {
            setTimeout(() => {
                this.totalRows = this.$store.getters.associateList.length;
            }, 1000);
        });
    },
    methods: {
        perPageChange(value) {
            this.$store.dispatch('updateAdminAsscPerPage', value);
        },
        onRowSelected(items) {
            this.selected = items[0];
            this.$store.commit('storeAdminSelectedUser', this.selected);
        },
        rightClicked (item) {
            this.selected = item;
            this.$store.commit('storeAdminSelectedUser', this.selected);
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFieldFormat(value) {
            return this.moment(value).format('MM/DD/YYYY h:mm A');
        },
        compFieldFormat(value) {
            const cList = this.$store.getters.compList;
            let compName = '';
            if (cList !== null) {
                compName = cList[value].name;
                if (compName.length > 16) {
                    compName = compName.substring(0, 13) + "...";
                }
            }
            return compName;
        },
        roleFieldFormat(value) {
            return this.roleOptions[value].text;
        },
        regFieldFormat(value) {
            return this.regOptions[value].text;
        },
        statusFieldFormat(value) {
            return this.statOptions[value].text;
        },
        refreshUserList() {
            this.$store.dispatch('getAdminUserList').then(() => {
                setTimeout(() => {
                    this.totalRows = this.$store.getters.associateList.length;
                }, 1000);
            });
        }
    },
    computed: {
        items() {
            return this.$store.getters.associateList;
        },
        selUser() {
            if (this.selected !== null && this.selected !== undefined)
                return this.selected.username
            else
                return '';
        }
    }
}
</script>

<style scoped>

</style>
